import { makeAutoObservable, runInAction } from "mobx"
import { RootStore } from "./RootStore";
// import { Country } from "../models/Country";
import BlockHelper from "./BlockHelper"
import { EuLegalAct } from "../models/EuLegalAct";
import { Unwrap } from "../utils/api/apiClient";
// import { EuLegalAct } from "../models/EuLegalAct";
// import { CustomLegalAct } from "../models/CustomLegalAct";


export default class SharedDataStore {
    rootStore : RootStore;
    // private _countries: Country[] | null = null;
    private _euLegalActs: EuLegalAct[] | null = null;
    // private _customLegalActs: CustomLegalAct[] | null = null;
    private _blockHelper = new BlockHelper();

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false, _blockHelper: false  } as any);
        this.rootStore = rootStore;
    }

    get euLegalActs() {
        this.getLegalActs();
        return this._euLegalActs;
    }

    private async getLegalActs() {
        if (this._euLegalActs != null || !this._blockHelper.tryBlock("legalacts")) return;

        let euLegalActs = Unwrap.result<EuLegalAct[]>(await this.rootStore.apiClient.get("legalacts/eu"));
        runInAction(() => {
            this._euLegalActs = euLegalActs;
            this._blockHelper.unblock("legalacts");
        });
    }

    clear() {
        this._euLegalActs = null;
        this._blockHelper.clear();
    }

}