import React from "react";
import authService from "../../utils/AuthService";
import { Component } from "react";

export class SignInCallback extends Component {
    componentDidMount() {
        authService.signInPopupCallback();
    }
    render() {
        return <div>Signing in...</div>
    }
}

export class SignOutCallback extends Component {
    componentDidMount() {
        authService.signOutPopupCallback();
    }
    render() {
        return <div>Signing out...</div>
    }
}

export class SignInSilentCallback extends Component {
    componentDidMount() {
        authService.signinSilentCallback();
    }
    
    render() {
        return <div>Signing in...</div>
    }
}