import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';

import RootStore from "./store/RootStoreContext";
import RootStoreInstance from "./store/RootStore";

import i18n from "./i18n";
import { SignInCallback, SignOutCallback, SignInSilentCallback } from "./components/Auth/Callback";
import { reaction } from "mobx";
import CompanyLogo from "./images/logos/company-logo_dark.png"
import lazyWithRetry from "./LazyWithRetry";

mixpanel.init('48ae6d5a8202659475ef397af64c7dcf');
if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  mixpanel.disable();
}

const Header = lazyWithRetry(() => import("./components/Layout/Header"))
const Sidebar = lazyWithRetry(() => import("./components/Layout/Sidebar"));
const Footer = lazyWithRetry(() => import("./components/Layout/Footer"));
const MainBody = lazyWithRetry(() => import("./MainBody"));
const MobileWarning = lazyWithRetry(() => import("./MobileWarning"));


reaction(() => {
  return RootStoreInstance.sessionStore.authenticated
}, (auth) => {
  if (auth) RootStoreInstance.sessionStore.loadCurrentLicense(false);
});

const App = function () {

  function appLoader() {
    return <div id="main-app-loader">
      <div><img src={CompanyLogo} alt="Logo" title="Logo" /></div>
    </div>
  }

  useEffect(() => {
    const w = window as any;
    const addScript = () => {
      const existing = document.getElementById("support-widget-script");
      if (!!existing) {
        w.FreshworksWidget("destroy");
        i18n.off("languageChanged", addScript);
        existing.remove();
      }
      w.fwSettings = {
        'widget_id': 80000004808,
        locale: i18n.languages[0]?.startsWith("zh") ? "zh-CN" : "en"
      };
      var func: any = function (all: any) {
        let n = func as any;
        n.q.push(arguments);
      }
      func.q = [];
      w.FreshworksWidget = func;
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.id = "support-widget-script";
      script.src = "https://euc-widget.freshworks.com/widgets/80000004808.js";
      script.addEventListener("load", () => {
        i18n.on("languageChanged", addScript);
        const lang = i18n.languages[0];
        let labels = { "zh-CN": { launcher: "帮助" } } as any;
        if (lang === "tr") labels.en = { launcher: "Yardım" };
        //This is extremly ugly but without the timeout, Freshworks widget don't add the labels and defaults to those defined by the admin page. Really only visible when switching language
        setTimeout(() => {
          w.FreshworksWidget("setLabels", labels);
        }, 850);
      });
      document.body.appendChild(script);
    };

    i18n.on("initialized", addScript);
  }, []);

  useEffect(() => {
    mixpanel.track("app-started");
  }, []);

  return <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
    <BrowserRouter>
      <RootStore>
        <Suspense fallback={appLoader()}>
          <Switch>
            <Route exact path="/account/signin-oidc" component={SignInCallback} />
            <Route exact path="/account/signout-oidc" component={SignOutCallback} />
            <Route exact path="/account/silent-renew" component={SignInSilentCallback} />
            <Route>
              <div className="layout-fixed">
                <div className="wrapper">
                  <Header />
                  <Sidebar />
                  <MobileWarning />
                  <MainBody />
                  <Footer />
                </div>
              </div></Route>
          </Switch>
        </Suspense>
      </RootStore>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
};

export default App;
