import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';


if (process.env.REACT_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: "https://8a34712cc55b4f6aad3b06242176ac11@o275291.ingest.sentry.io/5738198",
    ignoreErrors: [/loading chunk.+failed/i],
    environment: process.env.REACT_APP_ENVIRONMENT
  });
  setupLogRocketReact(LogRocket);
  LogRocket.init('zkyelu/clever-rep-app');
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()