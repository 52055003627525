import Swal from 'sweetalert2';

const defaultClasses = "btn btn-oval py-3 px-5 mx-2";

const alert = Swal.mixin({
    heightAuto: false,
    buttonsStyling: false,
    customClass: {
        confirmButton: "btn-primary " + defaultClasses,
        cancelButton: "btn-secondary " + defaultClasses
    }
});

const toast = Swal.mixin({
    toast: true,
    icon: "success",
    showConfirmButton: false,
    timer: 3000,
    position: "top-left"
});

const validationToast = Swal.mixin({
    toast: true,
    icon: "info",
    showConfirmButton: false,
    timer: 3000,
    position: "top",
    title: "Please fill in the required fields"
});

const confirmation = Swal.mixin({
    heightAuto: false,
    buttonsStyling: false,
    icon: "question",
    customClass: {
        confirmButton: "btn-primary " + defaultClasses,
        cancelButton: "btn-secondary " + defaultClasses
    },
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    showCancelButton: true
});

const dangerConfirmation = Swal.mixin({
    heightAuto: false,
    buttonsStyling: false,
    customClass: {
        confirmButton: "btn-danger " + defaultClasses,
        cancelButton: "btn-outline-primary " + defaultClasses
    },
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    showCancelButton: true,
    reverseButtons: true,
    icon: "warning"
});

export default Swal;
export { alert, toast, Swal, defaultClasses, validationToast, dangerConfirmation, confirmation }