import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import mixpanel from "mixpanel-browser";

i18n.on("languageChanged", (lang) => {
  if (lang === "zh") document.title = "授权代表 - Clever Representative"
  else document.title = "Authorised representative - by Clever Representative"

  mixpanel.register({
    language: lang
  })
})

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.REACT_APP_ENVIRONMENT !== "production",
    load: 'languageOnly',
    supportedLngs: ["en", "zh", "tr"],
    whitelist: ["en", "zh", "tr"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;