import { lazy } from 'react';

const key = "lazy-forced-refresh";

const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        //If local storage not available, we must set it's already been refreshed, to avoid an endless loop (since we can't save if it has been refresh and will continue to refresh)
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                key
            ) || 'false'
        )

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                key,
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    key,
                    'true'
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

export default lazyWithRetry;