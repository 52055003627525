export default class BlockHelper {
    private _blocked : string[] = [];

    isBlocked(id : string) {
        return this._blocked.includes(id);
    }

    tryBlock(id : string) {
        if (this.isBlocked(id)) return false;
        this._blocked.push(id);
        return true;
    }
    unblock(id : string) {
        this._blocked = this._blocked.filter(a => a !== id);
    }

    clear() {
        this._blocked = [];
    }

}