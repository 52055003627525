import { makeAutoObservable, runInAction } from "mobx"
import { User } from "oidc-client";
import { LicenseVm } from "../models/License";
import { ApiClient, Config, Unwrap } from "../utils/api/apiClient";
import authService from "../utils/AuthService";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";
import LogRocket from 'logrocket';
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

export default class SessionStore {
    user : User | null = null;
    private _license : LicenseVm | null | undefined = undefined;
    loading : boolean = false;
    private _disableLicenseLoading = false;
    private _blockHelper = new BlockHelper();
    
    //Root store added as param to ensure all stores have the same signature, regardless if they need it or not. Could be needed in the future.
    constructor(_ : RootStore) {
        makeAutoObservable(this, { loadCurrentLicense: false, _disableLicenseLoading: false } as any);
        authService.onUserSet((a: User) => this.setUser(a));

        authService.getUser().then(foundUser => { 
            if (foundUser) this.setUser(foundUser);
        });
    }

    clear() {
        this.setLicense(undefined);
        this.user = null;
        Sentry.configureScope(scope => scope.setUser(null));
        LogRocket.startNewSession();
        mixpanel.reset();
        this._blockHelper.clear();
    }

    private setUser(user : User) {
        this.user = user;
        if (!!user) {
            Sentry.setUser({ id: user.profile.sub, email: user.profile.email });
            LogRocket.identify(user.profile.sub, {
                name: user.profile.name ?? "No name",
                email: user.profile.email ?? "No email",
            });

            if (user.profile.email?.includes("clevercompliance")) mixpanel.opt_out_tracking();
            
            mixpanel.people.set({
                "$email": user.profile.email,
                "$name": user.profile.name,
            });
            mixpanel.identify(user.profile.sub);
            mixpanel.track("sign-in");
        }
        else this.clear();

    }

    get authenticated() {
        return !!this.user && !this.user.expired;
    }

    get apiClient() {
        let accessToken = this.user?.access_token;
        return ApiClient.Create({ accessToken })
    }

    createApiClient(config : Config) {
        return ApiClient.Create({ accessToken: this.user?.access_token, ...config });
    }

    disableLicenseLoading(val : boolean) {
        this._disableLicenseLoading = val;
    }

    private ifLoaded<T>(prop : T) : null | T | undefined {
        return this.loading ? undefined : prop;
    }

    get license() : LicenseVm | null | undefined {
        this.loadCurrentLicense();
        return this.ifLoaded(this._license);
    }

    private tryBlock() {
        return this._blockHelper.tryBlock("license");
    }
    private unblock() {
        return this._blockHelper.unblock("license");
    }

    setLicense(license : LicenseVm | null | undefined) {
        this._license = license;
        mixpanel.register({
            paid: !!license?.licensePermissions.allowRepresentation
        })
    }

    async loadCurrentLicense(bypassCache = false, enableLoading = true) : Promise<LicenseVm | null | undefined> {
        if (this._disableLicenseLoading || !this.tryBlock()) return undefined;
        
        if (!this.authenticated) {
            runInAction(() => {
                this.clear();
            });
            return;
        }
        if (bypassCache !== true && this._license !== undefined) {
            runInAction(() => {
                this.unblock();
            });
            return;
        }
        if (enableLoading) {
            runInAction(() => {
                this.loading = true;
            });
        }
        let license = Unwrap.result<LicenseVm | null>(await this.apiClient.get("licenses/current"));
        let self = this;
        runInAction(() => {
            self.setLicense(license);
            if (enableLoading) self.loading = false;
            this.unblock();
        });
        return license;
    }
    signIn() {
        authService.signIn();
    }
    signOut() {
        this.clear();
        authService.signOut();
    }
    promptSignIn(opts = {}) {
        authService.promptSignIn(opts)
    }
}